import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ReactSwipeButton from "react-swipe-button";
import theme from "../../theme";
import { AppContext } from "../../AppContext";
import { useState } from "react";
import { Actions } from "../../utils/constants";

const useStyles = makeStyles({
    user_initials: {
        padding: theme.spacing(2),
        flexDirection: "column",
    },
    problem_description: {
        padding: theme.spacing(2),
        flexDirection: "column",
    }
});

const UserInitialsText = ({ value, onChange, className }) => {
    return (
        <TextField
            id="user-initials-input"
            label="Your initials:"
            className={className}
            style={{ flex: 1 }}
            value={value}
            onChange={onChange}
            required
        />
    )
};

const UserProblemText = ({ value, onChange, className }) => {
    return (
        <TextField
            id="user-problem-input"
            label="Describe the problem:"
            className={className}
            style = {{ flex: 1 }}
            value={value}
            fullWidth
            multiline
            maxRows={2}
            onChange={onChange}
            required
        />
    )
};

export default function SendLogsDialog({
    closeFunc
}: {
    closeFunc: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const classes = useStyles();
    const [initials, setInitials] = useState(localStorage.getItem("user-initials") || "");
    const [problemDescription, setProblemDescription] = useState("");
    const { dispatch } = AppContext();

    const SendLog = () => {
        closeFunc(false);
        localStorage.setItem("user-initials", initials);
        dispatch({ type: Actions.SENDLOG, payload: { problemDescription } });
        toast.info(
            "Sending log to Drizzle.",
            {
                autoClose: 5000,
            }
        );
    };

    return (
        <div>
            <p>Do you want to send machine logs to Drizzle?</p>
            <p><em>When you send the log, Merlin400 will light red while sending and blink green when completed. Please do not interact with the machine or app while it is sending.</em></p>
            <UserInitialsText
                value={initials}
                onChange={(e) => {
                    const re = /^[A-Za-z.-]{1,6}$/;
                    if (e.target.value === '' || (re.test(e.target.value))) {
                        setInitials(e.target.value);
                    }
                }}
                className={classes.user_initials}
            />

            <UserProblemText
                value={problemDescription}
                onChange={(e) => {setProblemDescription(e.target.value)}}
                className={classes.problem_description}
            />

            <ReactSwipeButton
                text="Start sending logs"
                color="#4caf50"
                onSuccess={SendLog}
            />
        </div>
    );
}
