import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ErrorMessage = ({
  open,
  title,
  content,
  onClose,
}: {
  open: boolean;
  title: string;
  content: React.ReactNode;
  onClose: VoidFunction;
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="error-message-title"
    aria-describedby="error-message-description"
  >
    <DialogTitle id="error-message-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="error-message-description">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      <Button onClick={onClose} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default ErrorMessage;
