import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import { NavigationId } from "../../utils/constants";
import {
  DashboardRounded,
  GridOnRounded,
  SettingsRounded,
} from "@material-ui/icons";
import { default as NowProcessingBar } from "../NowProcessingBar/NowProcessingBar";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  menuRoot: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const { state, } = AppContext();
  const [value, setValue] = useState(`/${NavigationId.DASHBOARD}`);
  const { deviceIsConnected } = state;

  useEffect(() => {
    setValue(history.location.pathname.substring(1));
  }, [history.location.pathname]);

  const MenuBar = () => (
    <BottomNavigation
      value={value}
      onChange={(_, newValue) => {
        if (!deviceIsConnected && newValue === NavigationId.PROGRAMS) {
          toast.warn("Device is not connected.");
        } else {
          setValue(newValue);
          history.push(`/${newValue}`);
        };
      }}
      className={classes.menuRoot}
      showLabels
    >
      <BottomNavigationAction
        label="Dashboard"
        icon={<DashboardRounded />}
        value={NavigationId.DASHBOARD}
      />
      <BottomNavigationAction
        label="Programs"
        icon={<GridOnRounded />}
        value={NavigationId.PROGRAMS}
      />
      <BottomNavigationAction
        label="Settings"
        icon={<SettingsRounded />}
        value={NavigationId.SETTINGS}
      />
    </BottomNavigation>
  );

  return (
    <>
      <NowProcessingBar />
      <MenuBar />
    </>
  );
}
