import { ReactComponent as ImageHeader } from "./../assets/undraw_in_progress_ql66.svg";
import { StatusBox } from "./../Components";
import theme from "../theme";

const DashboardView = () => {
  return (
    <div>
      <ImageHeader
        style={{
          width: "100%",
          maxHeight: 100,
          margin: theme.spacing(4) + " 0",
        }}
      />
      <StatusBox />
    </div>
  );
};

export default DashboardView;
