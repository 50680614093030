import { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import { useHistory } from "react-router-dom";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import { makeStyles } from "@material-ui/core/styles";
import drizzleLogo from "./../../assets/drizzle-logo.png";
import { AppContext } from "../../AppContext";
import { Actions, NavigationId, TOPBAR_HEIGHT } from "../../utils/constants";
import { AlertDialog } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  appBar: {
    alignItems: "flex-end",
  },
  toolbar: {
    flexDirection: "row",
    height: TOPBAR_HEIGHT,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  toolbarRight: {
    display: "flex",
  },
  toolbarLogo: {
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    width: 150,
  },
  logoutButton: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  const { state, dispatch } = AppContext();
  const history = useHistory();
  const { channelId = "", fwVersion = "", latestVersion = "", log = [], runMinutesSince = 0, sinceDate = "", deviceIsConnected } = state;
  const fwAlertShown = (localStorage.getItem("drizzle-fw-version-0.112-shown") || "false") === "true";
  const showUpdate = (
    fwVersion !== "" && latestVersion !== "" &&
    fwVersion < "0.112" && latestVersion.startsWith("0.112") &&
    !fwAlertShown
  );
  const [openConfirm, setOpenConfirm] = useState(false);

  const deviceId = (channelId.substring(0, 7) === "0000000" ? channelId.substring(6, 16) : channelId.substring(0, 7))

  const logout = () => {
    dispatch({ type: Actions.LOGOUT });
    localStorage.clear();
    history.push(`/${NavigationId.LOGIN}`);
  }

  const detectStatus = (log) => {
    const deviceLog = log.find(logItem => Object.hasOwn(logItem, 'timestamp'));

    if (deviceLog !== undefined && Object.hasOwn(deviceLog, 'timestamp')) {
      const lastTimestamp = deviceLog['timestamp'];
      // If we received device timestamp within last 15 seconds, device is online.
      if (lastTimestamp * 1000 + 15 * 1000 > Date.now()) {
        return true;
      }

      // If we received device timestamp earlier than last 15 seconds, device is offline.
      if (lastTimestamp * 1000 + 15 * 1000 <= Date.now()) {
        return false;
      }

      // TODO: this is only for older FW versions, remove later.
      if (deviceLog != null && Object.hasOwn(deviceLog, 'firmwareVersion')) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (showUpdate) {
      localStorage.setItem("drizzle-fw-version-0.112-shown", "true");
      setOpenConfirm(true);
    }
  }, [setOpenConfirm, showUpdate])


  useEffect(() => {
    const status = detectStatus(log);
    console.log("Connected", status);

    const intervalId = setInterval(() => {
      const status = detectStatus(log);
      console.log("!Connected", status);

      if (!status) {
        dispatch({ type: Actions.DISCONNECTED, payload: { log: [] } });
      }
    }, 15000);

    return () => clearInterval(intervalId);
  }, [log, dispatch ])

  const AlertDialogUpdateContent = () => (
    <div>
      <p>We have updated the app and you need to upgrade your machine to make it compatible. Please use the upgrade firmware button on the settings pane to do this.</p>
    </div>
  );

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const gotoUpdate = () => {
    history.push(`/${NavigationId.SETTINGS}`);
    setOpenConfirm(false);
  }

  return (
    <div className={classes.root}>
      <AlertDialog
        open={openConfirm}
        title="Important Update Available"
        content={<AlertDialogUpdateContent />}
        onClose={closeConfirm}
        onAgree={gotoUpdate}
      />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div>
            { deviceIsConnected ? <LinkIcon fontSize="large"/> : <LinkOffIcon fontSize="large"/> }
            <Typography variant="body2" display="block" gutterBottom>
              { deviceIsConnected ? "Connected" : "Not Connected" }
            </Typography>
          </div>
          <div className={classes.toolbarRight}>
            <div className={classes.toolbarLogo}>
              <img src={drizzleLogo} alt="Drizzle" className={classes.logo} />
              <Typography variant="caption" display="block" gutterBottom>
                ID: {deviceId}, FW: {fwVersion} <br/> Run {runMinutesSince} minutes since {sinceDate} <br/>
                {latestVersion !== "" && fwVersion !== "" && fwVersion !== latestVersion ? " Update available": ""}
              </Typography>
            </div>
              <Button className={classes.logoutButton} endIcon={<ExitToAppIcon />} onClick={logout}/>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
