import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { LinearProgressWithLabel } from "..";

const Gauge = ({
  label,
  value,
  max,
  unit,
}: {
  label?: string;
  value: number;
  max: number;
  unit?: string;
}) => {
  return (
    <Box>
      {label && (
        <Typography variant="overline" component="div" color="textSecondary">
          {label}
        </Typography>
      )}
      <LinearProgressWithLabel
        color="secondary"
        value={(value / max) * 100}
        noLabel={true}
      />
      <Typography variant="caption" component="div" color="textSecondary">
        {value?.toFixed(1) ?? "?"} {unit}
      </Typography>
    </Box>
  );
};

export default Gauge;
