import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    marginRight: theme.spacing(1),
  },

  noLabel: {
    marginRight: 0,
  },
}));

export default function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    noLabel?: boolean;
  }
) {
  const { noLabel, value, color } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box
        width="100%"
        mr={1}
        className={noLabel ? classes.noLabel : classes.label}
      >
        <LinearProgress
          variant="determinate"
          color={color || "primary"}
          value={value}
        />
      </Box>
      {!noLabel && (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
}
