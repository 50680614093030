import {MouseEvent, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { PauseCircleFilled, PlayCircleFilled } from "@material-ui/icons";

import { AppContext } from "../../AppContext";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import {
  Actions,
  MachineStateType,
  NavigationId,
  ProgramId,
  ProgramsType,
} from "../../utils/constants";
import { Programs } from "../../data";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ProcessingRoot: {
      width: "100%",
      height: 55,
      position: "fixed",
      bottom: 55,
      left: 0,
      right: 0,
      background: "#fff",
    },

    controls: {
      display: "flex",
      alignItems: "right",
    },

    startIcon: {
      height: 75,
      width: 75,
    },

    iconButton: {
      padding: 0,
      position: "absolute",
      '@media (min-width: 780px)': {
        left: '47%'
      },
      left: "43%",
      top: "-100px",
    },

    percentageText: {
      ...theme.typography.h2,
      lineHeight: "50px",
      fontSize: theme.spacing(4),
      fontWeight: 100,
      marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },

    programName: {
      flex: "1 1 auto",
      paddingTop: 5,
    },

    statuslabel: { lineHeight: 1 },
  })
);

const NowProcessingBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const { state, dispatch } = AppContext();
  const {
    activeProgram: { programId, progress, warning }, log = [], deviceIsConnected
  } = state;

  const [progressBarValue, setProgressBarValue] = useState<number>(progress * 100)
  const program: ProgramsType | null =
    Programs.find((p) => p.id === programId || p.id === localStorage.getItem("drizzle-programId")) || null;
  const currentMachineState: MachineStateType =
    state.machineState || MachineStateType.IDLE;
  const isWorking = currentMachineState === MachineStateType.RUNNING;
  const programParameters = {};
  const isWarning = Boolean(warning && !localStorage.getItem("drizzle-warning"));
  const isActionLog = (l) => {return Object.hasOwn(l, 'action')};
  const getProgramId = (l) => {return isActionLog(log[0]) ? l.program : l.activeProgram.programId};

  const resume = (e: MouseEvent) => {
    if (!deviceIsConnected) {
      toast.warn("Device is not connected.");
    } else {
      const isStarted = isActionLog(log[0]) ? log[0].action === Actions.START : log[0].machineState === "running"
      if(isStarted && getProgramId(log[0]) === localStorage.getItem("drizzle-programId")){
        dispatch({ type: Actions.RESUME });
      } else {
        if (!!program && program.id === ProgramId.PROGRAM_1) {
          programParameters["soakTime"] = localStorage.getItem("drizzle-soak-time") || null;
        }
        localStorage.removeItem("drizzle-warning");
        dispatch({
          type: Actions.START,
          payload: {
            programId: localStorage.getItem("drizzle-programId") || programId,
            progress: 0,
            programParameters: programParameters,
          },
        });
      }
    }

    e.stopPropagation();
  };

  const pause = (e: MouseEvent) => {
    if (!deviceIsConnected) {
      toast.warn("Device is not connected.");
    } else {
      dispatch({ type: Actions.PAUSE });
    }
    e.stopPropagation();
  };

  const goToDashboard = () => {
    history.push(`/${NavigationId.DASHBOARD}`);
  };

  useEffect(() => {
    if (isWarning) {
      toast.warn(warning, {
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
      });
      localStorage.setItem("drizzle-warning", warning);
    }
  }, [isWarning, warning])

  useEffect(() => {
    setTimeout(() => {
      setProgressBarValue(progress)
    }, 500)

  }, [progress])

  return program ? (
    <div className={classes.ProcessingRoot} onClick={goToDashboard}>
      <LinearProgressWithLabel value={progressBarValue} noLabel />
      <Grid container>
        <Grid item>
          <div className={classes.percentageText}>{`${Math.round(
            progressBarValue
          )}%`}</div>
        </Grid>
        <Grid item className={classes.programName}>
          <Typography component="h5" variant="h5">
            {program?.name || ""}
          </Typography>

        </Grid>
        <Grid item>
          <div className={classes.controls}>
            {isWorking ? (
              <IconButton onClick={pause} className={classes.iconButton}>
                <PauseCircleFilled
                  color="primary"
                  className={classes.startIcon}
                />
              </IconButton>
            ) : (
              <IconButton onClick={resume} className={classes.iconButton}>
                <PlayCircleFilled
                  color="primary"
                  className={classes.startIcon}
                />
              </IconButton>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  ) : null;
};
export default NowProcessingBar;
