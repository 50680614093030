import { useState, useEffect, useCallback } from "react";
import { PubSub } from "aws-amplify";
import { toast } from "react-toastify";

export const iotMachinePublish = (
  channelId: string,
  payload: { [key: string]: any }
) =>
  PubSub.publish(`device/${channelId}/data`, {
    ...payload,
    clientId: channelId,
    receiver: "client",
  });

export const iotPublish = (
  channelId: string,
  payload: { [key: string]: any }
) =>
  PubSub.publish(`device/${channelId}/data`, {
    ...payload,
    clientId: channelId,
    receiver: "machine",
  });

const useIOT = ({ channelId }: { channelId: string }) => {
  const [iotState, setIotState] = useState<any[]>([]);
  const [error, setError] = useState<{ errorMessage: string }>();
  const [isClosed, setIsClosed] = useState(false);

  const getSubscription = useCallback(
    () =>
      PubSub.subscribe(`device/${channelId}/data`).subscribe({
        next: (response) => setIotState([response.value, ...iotState]),
        error: (error) => setError(error),
        complete: () => setIsClosed(false),
      }),
    [iotState, channelId]
  );

  useEffect(() => {
    if (!channelId) return;
    const sub = getSubscription();
    return () => sub.unsubscribe();
  }, [channelId, getSubscription]);

  if (isClosed) toast.error("Connection to server closed unexpectedly.");

  if (error?.errorMessage) {
    console.error(error);
    toast.warn(
      error.errorMessage || error || "Generic error - please refresh page."
    );
  }

  return { iotState, iotPublish };
};

export default useIOT;
