import { ReactNode, useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { AppContextProvider, AppContext } from "./AppContext";

import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";
import { Storage, Amplify } from "aws-amplify";

import { toast, ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import {
  BottomBar,
  ProminentAppBar,
  ContentWrapper,
  DebugMachinePanel,
  ErrorMessage,
} from "./Components";
import DashboardView from "./Views/Dashboard";
import { useNavigatorOnLine } from "./utils";

import "react-toastify/dist/ReactToastify.css";
import LoginView from "./Views/Login";
import { Actions, MachineActions, NavigationId } from "./utils/constants";
import ProgramsView from "./Views/Programs";
import SettingsView from "./Views/Settings";
import useIOT from "./utils/useIOT";
import Axios from "axios";
import { mapDataFromAPItoStore } from "./utils/mappers";
import config from "./config";

Amplify.configure({
  Auth: {
    identityPoolId: config.IDENTITY_POOL_ID,
    region: config.REGION,
    userPoolId: config.USER_POOL_ID,
    userPoolWebClientId: config.USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "API",
        endpoint: config.API_URL,
      },
    ],
  },
  Storage: {
    AWSS3: {
        bucket: config.FW_BUCKET,
        region: config.REGION,
    }
  },
});

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: config.REGION,
    aws_pubsub_endpoint: `wss://${config.MQTT_ID}.iot.${config.REGION}.amazonaws.com/mqtt`,
  })
);

function IOTWrapper({
  channelId,
  children,
}: {
  channelId: string;
  children: ReactNode;
}) {
  const { dispatch, state } = AppContext();
  const { iotState, iotPublish } = useIOT({ channelId });
  const { log } = state;
  const currentLog = log?.length ? log[0]["log"] : null;
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (currentLog) {
      setShowError(true);
    }
  }, [currentLog]);

  useEffect(() => {
    const lastItem = iotState[0] ?? null;
    if (!lastItem) return;

    // react to any new message in channel
    if (lastItem.receiver === "client") {
      dispatch({
        type: Actions.STATUS,
        payload: {
          activeProgram: lastItem.activeProgram,
          machineState: lastItem.machineState,
          machineVersion: lastItem.machineVersion,
          firmwareVersion: lastItem.firmwareVersion,
          runMinutesSince: lastItem.runMinutesSince,
          sinceDate: lastItem.sinceDate,
          log: lastItem.log,
          machineSoakTimeSeconds: lastItem.programParameters?.soakTime || null,
        },
      });
    }

    // save every interaction to UI log
    dispatch({
      type: Actions.LOGDUMP,
      payload: { log: iotState },
    });
  }, [iotState, dispatch]);

  useEffect(() => {
    // get the latest machine status from API
    const url = `${config.API_URL}/machine/${channelId}`;
    Axios.get(url)
      .then((response) => {
        const { data } = response;

        const expireDuration = 1000 * 60 * 1; // 1 minute
        const timestamp = data.timestamp;

        // If received status data is outdated, set machine state to idle,
        // it will be updated from iot messages later on.
        const statusData = (timestamp + expireDuration > Date.now()) ? data : { ...data, machineState: "idle", programId: "none"};

        // On app start, remove previously set warning flag.
        localStorage.removeItem("drizzle-warning");

        dispatch({
          type: Actions.STATUS,
          payload: mapDataFromAPItoStore(statusData),
        });

        if (timestamp + expireDuration < Date.now()) {
          iotPublish(channelId, {
            action: MachineActions.STATUS,
          });
        }
      })
      .catch((err) => {
        toast.info(`Cannot connect to Merlin400 with the entered login credentials. Please select logout using the icon in the top right corner and log in with the correct credentials on your Merlin400 sticker.`, {
          autoClose: 20000,
        });
        console.error(err);
      });
  }, [channelId, iotPublish, dispatch]);

  return <>
    <ErrorMessage
      open={showError}
      title="Error occurred"
      content={<>{currentLog}</>}
      onClose={() => setShowError(false)}
    />
    {children}
    </>;
}

function PrivateRoute({ children, ...rest }) {
  const { state } = AppContext();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        state.channelId ? (
          <IOTWrapper channelId={state.channelId}>
            <ProminentAppBar />
            <ContentWrapper>{children}</ContentWrapper>
            <BottomBar />
          </IOTWrapper>
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
}

function GetLatestFWVersion() {
    Storage.get('VERSION', {download: true}).then(result => {
      // @ts-ignore
      result["Body"]?.text().then(string => {
        window.localStorage.setItem("drizzle-latest-fw-version", string.trim());
      });
    })
    .catch(err => {
      console.log(err);
    });
  }

const App = () => {
  const isOnline = useNavigatorOnLine();
  if (!isOnline) toast.warn("No internet connection.");

  GetLatestFWVersion();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const from = query.get("from");
    const cid = query.get("cid");

    if (from === "machine" && cid !== "") {
      window.localStorage.setItem("drizzle-cid", cid || "");
      window.location.replace("/");
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path="/machine">
              <DebugMachinePanel />
            </Route>
            <Route exact path={`/${NavigationId.LOGIN}`}>
              <LoginView />
            </Route>

            <PrivateRoute path={`/${NavigationId.DASHBOARD}`}>
              <DashboardView />
            </PrivateRoute>
            <PrivateRoute path={`/${NavigationId.PROGRAMS}`}>
              <ProgramsView />
            </PrivateRoute>
            <PrivateRoute path={`/${NavigationId.SETTINGS}`}>
              <SettingsView />
            </PrivateRoute>

            <Redirect to={`/${NavigationId.DASHBOARD}`} />
          </Switch>
        </BrowserRouter>

        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          pauseOnFocusLoss={false}
          newestOnTop
          closeOnClick
          draggable
          pauseOnHover
        />
      </AppContextProvider>
    </ThemeProvider>
  );
};

export default App;
