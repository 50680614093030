import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { StopRounded } from "@material-ui/icons";
import ReactSwipeButton from "react-swipe-button";

import { Gauge, AlertDialog } from "..";
import {
  Actions,
  MachineStateType,
  NavigationId,
  ProgramId,
  ProgramsType,
} from "../../utils/constants";
import { Programs } from "../../data";
import { AppContext } from "../../AppContext";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      padding: 0,
      paddingTop: theme.spacing(1),
    },
    details: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: theme.spacing(2),
    },
    content: {
      flex: "1 0 auto",
      padding: 0,
      // paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    controls: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    cardDetails: {
      display: "flex",
      flexDirection: "row",
    },
    startIcon: {
      height: 38,
      width: 38,
    },
    helpText: {
      marginTop: theme.spacing(2),
      textAlign: "center",
      cursor: "pointer",
    },
  })
);

const StatusBox = () => {
  const history = useHistory();
  const [openConfirm, setOpenConfirm] = useState(false);
  const classes = useStyles();
  const { state, dispatch } = AppContext();
  const {
    activeProgram: { programId, pressure, temperature, power, currentAction, timeElapsed }, deviceIsConnected
  } = state;
  const program: ProgramsType | null =
    Programs.find((p) => p.id === programId || p.id === localStorage.getItem("drizzle-programId")) || null;
  const hasActiveProgram = programId !== ProgramId.NONE;
  const currentMachineState: MachineStateType =
    state.machineState || MachineStateType.IDLE;

  const isWorking = currentMachineState === MachineStateType.RUNNING;
  // Display time elapsed only when it's set and there is active program.
  const displayTimeStatus = timeElapsed && hasActiveProgram && isWorking;

  const stop = () => {
    localStorage.removeItem("drizzle-programId");
    dispatch({
      type: Actions.STOP,
      payload: { activeProgram: program },
    });
    closeConfirm();
  };

  const stopConfirm = () => {
    if (deviceIsConnected) {
      setOpenConfirm(true);
    } else {
      toast.warn("Device is not connected.");
    };
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const secondsToHms = (seconds) => {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m >= 0 ? m + (m === 1 ? " min" : " min ") : "";
    return hDisplay + mDisplay;
  };

  const gotoPrograms = () => {
    if (deviceIsConnected) {
      history.push(`/${NavigationId.PROGRAMS}`);
    } else {
      toast.warn("Device is not connected.");
    };
  };

  const AlertDialogContent = () => (
    <div>
      <p>Are you sure you want to stop the program?</p>
      <ReactSwipeButton text="Stop program" color="#f44336" onSuccess={stop} />
    </div>
  );

  return (
    <>
      <AlertDialog
        open={openConfirm}
        title="Stop program"
        content={<AlertDialogContent />}
        onClose={closeConfirm}
      />

      <Card className={classes.root}>
        <div className={classes.details}>
          <>
          <div className={classes.cardDetails}>
              <CardContent className={classes.content}>
                <Typography component="span" variant="caption">
                  Program
                </Typography>
                <Typography component="h5" variant="h5">
                  {program?.name || "-"}{" "}
                  {isWorking && <CircularProgress color="primary" size={20} />}
                </Typography>
                <Typography
                  component="span"
                  variant="caption"
                  style={{ wordWrap: "break-word", width: "8rem", display: "block" }}>
                {displayTimeStatus ? (
                  "Time Elapsed: " + secondsToHms(timeElapsed) + "."
                ) : ""
                }
                </Typography>
              </CardContent>

              <CardContent className={classes.content}>
                <Typography component="span" variant="caption">
                  Current Action
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {currentAction ? currentAction : "Idle"}
                </Typography>
              </CardContent>
            </div>
          </>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Gauge label="Pressure" value={pressure} max={1100} unit={"mbar"} />
            </Grid>
            <Grid item xs={6}>
              <Gauge
                label="Heater Temp"
                value={temperature}
                max={200}
                unit={"°C"}
              />
            </Grid>
            <Grid item xs={6}>
              <Gauge
                label="Power"
                value={power ? power: 0}
                max={100}
                unit={"%"}
              />
            </Grid>
          </Grid>
        </div>
      </Card>

      {(programId === ProgramId.NONE || !program) && !isWorking ? (
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.helpText}
          onClick={gotoPrograms}
        >
          Select a program to start...
        </Typography>
      ) : (
        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
          <Button
            startIcon={<StopRounded color="error" />}
            onClick={stopConfirm}
          >
            Stop program
          </Button>
        </Grid>
      )}
    </>
  );
};

export default StatusBox;
