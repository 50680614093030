import { useState } from "react";
import { toast } from "react-toastify";
//import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppContext } from "../../AppContext";
import { Actions, NavigationId } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      maxWidth: 375,
      margin: "0 auto",
      padding: theme.spacing(2),
      backgroundColor: "#fff",
      borderRadius: theme.spacing(0.5),
    },
    input: {
      marginBottom: theme.spacing(2),
    },
  })
);

export default function LoginForm() {
  const classes = useStyles();
  const { dispatch } = AppContext();
  const history = useHistory();
  const persistedMerlinID =
    localStorage.getItem("merlin-id") || "";
  const persistedMerlinPassword =
    localStorage.getItem("merlin-password") || "";
  const persistedChannelId =
    localStorage.getItem("drizzle-cid") || "";
  const [channelPasswd, setChannelPasswd] = useState(persistedMerlinPassword);
  const [channelMerlinID, setChannelMerlinID] = useState(persistedMerlinID);
  const [, setChannelId] = useState(persistedChannelId);

  const login = () => {
    if (channelMerlinID.length !== 7) {
      toast.warn("You have to enter the Merlin ID in the format mXXXXXX");
      return;
    }

    if (channelPasswd.length !== 8) {
      toast.warn("You have to enter the password in the format xxxxxxxx");
      return;
    }

    //merge the two strings
    let mergedCid = `${channelMerlinID}_${channelPasswd}`;

    //persist them
    localStorage.setItem("drizzle-cid", mergedCid);
    setChannelId(mergedCid);
    dispatch({ type: Actions.LOGIN, payload: { channelId: mergedCid } });
    history.push(`/${NavigationId.DASHBOARD}`);
  };

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={(event) => {
        login();
        event.preventDefault();
        return false;
      }}
    >
      <TextField
        id="id-input-field"
        label="SSID"
        className={classes.input}
        value={channelMerlinID}
        onChange={(e) => setChannelMerlinID(e.target.value)}
        fullWidth
        required
      />
      <TextField
        id="password-input-field"
        label="Password"
        className={classes.input}
        value={channelPasswd}
        onChange={(e) => setChannelPasswd(e.target.value)}
        fullWidth
        required
      />
      <Button variant="contained" fullWidth color="primary" onClick={login}>
        Start
      </Button>
    </form>
  );
}
