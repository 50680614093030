export enum MachineActions {
  "RESUME" = "resume",
  "START" = "start",
  "PAUSE" = "pause",
  "STOP" = "stop",
  "STATUS" = "status",
  "UPGRADE" = "upgrade",
  "SENDLOG" = "sendlog",
  "RESETMACHINE" = "reset",
}

export enum MachineStateType {
  "RUNNING" = "running",
  "PAUSED" = "paused",
  "IDLE" = "idle",
}
export enum Actions {
  "LOGIN" = "login",
  "LOADING" = "loading",
  "STATUS" = "status",
  "RESUME" = "resume",
  "START" = "start",
  "PAUSE" = "pause",
  "STOP" = "stop",
  "VERSION" = "version",
  "LOGDUMP" = "logdump",
  "LOGOUT" = "logout",
  "UPGRADE" = "upgrade",
  "SENDLOG" = "sendlog",
  "RESETMACHINE" = "reset",
  "DISCONNECTED" = "disconnected",
  "CLEAN_VALVE_1" = "clean_valve1",
  "CLEAN_VALVE_2" = "clean_valve2",
  "CLEAN_VALVE_3" = "clean_valve3",
  "CLEAN_VALVE_4" = "clean_valve4",
}

export enum NavigationId {
  "LOGIN" = "login",
  "DASHBOARD" = "dashboard",
  "PROGRAMS" = "programs",
  "SETTINGS" = "settings",
}

export enum ProgramId {
  "NONE" = "none",
  "PROGRAM_1" = "program01",
  "PROGRAM_2" = "program02",
  "PROGRAM_3" = "program03",
  "PROGRAM_4" = "program04",
  "PROGRAM_5" = "program05",
  "PROGRAM_6" = "program06",
  "PROGRAM_7" = "program07",
  "PROGRAM_UPGRADE" = "programUpgrade"
}

export type ActiveProgram = {
  programId: ProgramId;
  progress: number;
  pressure: number;
  temperature: number;
  power: number;
  currentAction: string;
  estimatedTimeLeft: number;
  timeElapsed: number;
  warning: string;
};

type LogItem = {
  action: Actions,
  clientId: string,
  program?: string,
  receiver: string,
  timestamp: number,
  machineState: string,
  log?: string
}

export type State = {
  channelId: string;
  fwVersion: string;
  latestVersion: string;
  isLoading: boolean;
  runMinutesSince: number;
  sinceDate: string;
  machineVersion: number;
  activeProgram: ActiveProgram;
  machineState?: MachineStateType;
  deviceIsConnected: boolean;
  log?: LogItem[];
};

export type ProgramStatusLabel = {
  minValue: number;
  label: string;
};

export type ProgramsType = {
  id: ProgramId;
  name: string;
  description: string;
  icon: string;
  color: string;
  statusLabels: ProgramStatusLabel[];
};

export const TOPBAR_HEIGHT = 85;
