import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Button, CardActions } from "@material-ui/core";
import ReactSwipeButton from "react-swipe-button";

import { AppContext } from "../AppContext";
import theme from "../theme";
import { Actions } from "../utils/constants";
import { AlertDialog } from "../Components";
import { useState } from "react";
import { SendLogsDialog } from "../Components"


const SettingsView = (props: any) => {
  const { state } = AppContext();
  const useStyles = makeStyles({
    root: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    log: {
      width: "100%",
      border: "1px solid #eee",
      background: "#f8f8f8",
      height: 300,
      overflow: "auto",
    },
    valve_buttons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: theme.spacing(2),
    },
    valve_button: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      left: theme.spacing(1),
    },
  });

  const classes = useStyles();
  const { dispatch } = AppContext();
  const [showModal, setShowModal] = useState(false);
  const [showResetMachine, setShowResetMachine] = useState(false);
  const [showModalCleanValve1, setShowModalCleanValve1] = useState(false);
  const [showModalCleanValve2, setShowModalCleanValve2] = useState(false);
  const [showModalCleanValve3, setShowModalCleanValve3] = useState(false);
  const [showModalCleanValve4, setShowModalCleanValve4] = useState(false);
  const [showModalSendLog, setShowModalSendLog] = useState(false);
  const { deviceIsConnected } = state;

  const AlertDialogContent = () => (
    <div>
      <p>Do you want to start upgrade process?</p>
      <ReactSwipeButton
        text="Start upgrade"
        color="#4caf50"
        onSuccess={startFirmwareUpdate}
      />
    </div>
  );

  const startFirmwareUpdate = () => {
    dispatch({ type: Actions.UPGRADE });
    setShowModal(false);
    toast.info(
      "Upgrading Merlin400. Do not switch off the machine. Expected updatetime 2 minutes.",
      {
        autoClose: 120000,
      }
    );
  };

  const AlertDialogResetMachine = () => (
    <div>
      <p>Do you want to reset the machine?</p>
      <ReactSwipeButton
        text="Reset Machine"
        color="#4caf50"
        onSuccess={ResetMachine}
      />
    </div>
  );

  const ResetMachine = () => {
    setShowResetMachine(false);
    localStorage.removeItem("drizzle-programId");
    dispatch({ type: Actions.RESETMACHINE });
    toast.info(
      "Resetting Machine.",
      {
        autoClose: 5000,
      }
    );
  };

  const AlertDialogContentCleanValve1 = () => (
    <div>
      <p>Set valves to clean valve 1?</p>
      <ReactSwipeButton
        text="Go"
        color="#4caf50"
        onSuccess={cleanValve1}
      />
    </div>
  );

  const AlertDialogContentCleanValve2 = () => (
    <div>
      <p>Set valves to clean valve 2?</p>
      <ReactSwipeButton
        text="Go"
        color="#4caf50"
        onSuccess={cleanValve2}
      />
    </div>
  );

  const AlertDialogContentCleanValve3 = () => (
    <div>
      <p>Set valves to clean valve 3?</p>
      <ReactSwipeButton
        text="Go"
        color="#4caf50"
        onSuccess={cleanValve3}
      />
    </div>
  );

  const AlertDialogContentCleanValve4 = () => (
    <div>
      <p>Set valves to clean valve 4?</p>
      <ReactSwipeButton
        text="Go"
        color="#4caf50"
        onSuccess={cleanValve4}
      />
    </div>
  );

  const cleanValve1 = () => {
    setShowModalCleanValve1(false);
    dispatch({ type: Actions.CLEAN_VALVE_1 });
    toast.info(
      "Setting valves position to allow cleaning valve 1.",
      {
        autoClose: 10000,
      }
    );
  };

  const cleanValve2 = () => {
    setShowModalCleanValve2(false);
    dispatch({ type: Actions.CLEAN_VALVE_2 });
    toast.info(
      "Setting valves position to allow cleaning valve 2.",
      {
        autoClose: 10000,
      }
    );
  };

  const cleanValve3 = () => {
    setShowModalCleanValve3(false);
    dispatch({ type: Actions.CLEAN_VALVE_3 });
    toast.info(
      "Setting valves position to allow cleaning valve 3.",
      {
        autoClose: 10000,
      }
    );
  };

  const cleanValve4 = () => {
    setShowModalCleanValve4(false);
    dispatch({ type: Actions.CLEAN_VALVE_4 });
    toast.info(
      "Setting valves position to allow cleaning valve 4.",
      {
        autoClose: 10000,
      }
    );
  };

  return (
    <div>
      <AlertDialog
        open={showModal}
        title="Start Upgrade?"
        content={<AlertDialogContent />}
        onClose={() => setShowModal(false)}
      />

      <AlertDialog
        open={showModalSendLog}
        title="Start Send Log?"
        content={<SendLogsDialog closeFunc={setShowModalSendLog} />}
        onClose={() => setShowModalSendLog(false)}
      />

      <AlertDialog
        open={showResetMachine}
        title="Reset Machine?"
        content={<AlertDialogResetMachine />}
        onClose={() => setShowResetMachine(false)}
      />

      <AlertDialog
        open={showModalCleanValve1}
        title="Cleaning Mode"
        content={<AlertDialogContentCleanValve1 />}
        onClose={() => setShowModalCleanValve1(false)}
      />

      <AlertDialog
        open={showModalCleanValve2}
        title="Cleaning Mode"
        content={<AlertDialogContentCleanValve2 />}
        onClose={() => setShowModalCleanValve2(false)}
      />

      <AlertDialog
        open={showModalCleanValve3}
        title="Cleaning Mode"
        content={<AlertDialogContentCleanValve3 />}
        onClose={() => setShowModalCleanValve3(false)}
      />

      <AlertDialog
        open={showModalCleanValve4}
        title="Cleaning Mode"
        content={<AlertDialogContentCleanValve4 />}
        onClose={() => setShowModalCleanValve4(false)}
      />

      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Upgrade
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Upgrade to newest firmware version.
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            Upgrade Firmware
          </Button>
        </CardActions>
      </Card>

      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Help
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Do you need help with you machine? Visit the FAQ on our website.{" "}
            <a
              href="https://drizzle.life/merlin/"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.drizzle.life/merlin
            </a>{" "}
            or contact us on{" "}
            <a
              href={`mailto:support@drizzle.life?subject=${encodeURIComponent(
                `Help with merlin with ID: ${state.channelId}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              support@drizzle.life
            </a>{" "}
            for more information.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModalSendLog(true)}
            disabled={!deviceIsConnected}
          >
            Send machine log
          </Button>
        </CardActions>

      </Card>

      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Reset Machine
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Reset your Drizzle Machine.
          </Typography>
        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowResetMachine(true)}
          >
            Reset Machine
          </Button>
        </CardActions>
      </Card>

      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Manual valve control
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Select a valve to open. The other valves will close. Use this function to clean valves. Before using it, ensure the machine is in ready mode and not running any programs. Also, empty the liquid reservoir and the other chambers of alcohol before using this function.
          </Typography>
        </CardContent>
        <CardActions className={classes.valve_buttons}>
          <Button
            className={classes.valve_button}
            variant="contained"
            color="primary"
            onClick={() => setShowModalCleanValve1(true)}
            disabled={!deviceIsConnected}
          >
            Liq Ch-Ex Ch (Valve 1)
          </Button>

          <Button
          className={classes.valve_button}
            variant="contained"
            color="primary"
            onClick={() => setShowModalCleanValve2(true)}
            disabled={!deviceIsConnected}
          >
            Air-Ex Ch (Valve 2)
          </Button>

          <Button
            className={classes.valve_button}
            variant="contained"
            color="primary"
            onClick={() => setShowModalCleanValve3(true)}
            disabled={!deviceIsConnected}
          >
            Ex-Dist Ch (Valve 3)
          </Button>

          <Button
            className={classes.valve_button}
            variant="contained"
            color="primary"
            onClick={() => setShowModalCleanValve4(true)}
            disabled={!deviceIsConnected}
          >
            Air-Dist Ch (Valve 4)
          </Button>

        </CardActions>
      </Card>

      {/* Disabled because it's not needed anymore.
      <Card className={classes.root}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Log
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            To improve the software of our machines you can see the complete
            messagelog between the machine and our cloud solution in the field
            below.
          </Typography>

          <pre className={classes.log}>
            {state.log?.length ? JSON.stringify(state.log, null, 2) : ""}
          </pre>
        </CardContent>
      </Card> */}
    </div>
  );
};

export default SettingsView;
