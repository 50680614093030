import { ReactNode, createContext, useReducer, useContext } from "react";
import {
  ProgramId,
  Actions,
  MachineStateType,
  State,
  MachineActions,
} from "./utils/constants";
import { iotPublish } from "./utils/useIOT";

type Action = { type: Actions; payload?: any };
type Dispatch = (action: Action) => void;
type ProviderProps = { children: ReactNode };

const initialState: State = {
  channelId: localStorage.getItem("drizzle-cid") || "",
  fwVersion: localStorage.getItem("drizzle-fw-version") || "",
  latestVersion: localStorage.getItem("drizzle-latest-fw-version") || "",
  isLoading: false,
  machineVersion: 0,
  machineState: MachineStateType.IDLE,
  runMinutesSince: 0,
  sinceDate: "",
  activeProgram: {
    programId: ProgramId.NONE,
    progress: 1,
    pressure: 0,
    temperature: 0,
    power: 0,
    currentAction: "",
    estimatedTimeLeft: 0,
    timeElapsed: 0,
    warning: ""
  },
  log: [],
  deviceIsConnected: false,
};

const CountStateContext =
  createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

function AppReducer(state: State, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case Actions.STOP: {
      iotPublish(state.channelId, {
        action: MachineActions.STOP,
      });

      return {
        ...state,
        machineState: MachineStateType.IDLE,
        activeProgram: {
          ...state.activeProgram,
          programId: localStorage.getItem("drizzle-programId") as ProgramId || ProgramId.NONE,
          progress: 1,
        },
      };
    }

    case Actions.PAUSE: {
      iotPublish(state.channelId, {
        action: MachineActions.PAUSE,
      });

      return {
        ...state,
        machineState: MachineStateType.PAUSED,
      };
    }

    case Actions.RESUME: {
      iotPublish(state.channelId, {
        action: MachineActions.RESUME,
      });

      return {
        ...state,
        machineState: MachineStateType.RUNNING,
      };
    }

    case Actions.UPGRADE: {
      iotPublish(state.channelId, {
        action: MachineActions.UPGRADE,
      });

      return {
        ...state,
        machineState: MachineStateType.RUNNING,
        activeProgram: {
          ...state.activeProgram,
          programId: ProgramId.PROGRAM_UPGRADE,
          progress: 0,
        },
      };
    }

    case Actions.SENDLOG: {
      iotPublish(state.channelId, {
        action: MachineActions.SENDLOG,
        problemDescription: payload.problemDescription,
        programParameters: {
          initials: localStorage.getItem("user-initials") || ""
        }
      });

      return {
        ...state,
        activeProgram: {
          ...state.activeProgram,
          progress: 0,
        },
      };
    }
    case Actions.START: {
      iotPublish(state.channelId, {
        action: MachineActions.START,
        program: payload.programId,
        programParameters: payload.programParameters,
      });

      return {
        ...state,
        machineState: MachineStateType.RUNNING,
        activeProgram: {
          ...state.activeProgram,
          programId: payload.programId,
          progress: 0,
        },
      };
    }

    case Actions.VERSION:
      return { ...state, machineVersion: payload.machineVersion };

    case Actions.LOGDUMP:
      return {
        ...state,
        log: payload.log,
      };

    case Actions.STATUS:
      localStorage.setItem("drizzle-fw-version", payload.firmwareVersion);
      localStorage.setItem("machine-soak-time", payload.machineSoakTimeSeconds);
      localStorage.setItem("drizzle-run-minutes", payload.runMinutesSince);
      localStorage.setItem("drizzle-run-since", payload.sinceDate);
      console.log("Time Elapsed", payload.activeProgram.timeElapsed);

      return {
        ...state,
        machineState: payload.machineState,
        fwVersion: payload.firmwareVersion,
        machineVersion: payload.machineVersion,
        machineSoakTimeSeconds: payload.machineSoakTimeSeconds,
        timestamp: payload.timestamp,
        runMinutesSince: payload.runMinutesSince,
        sinceDate: payload.sinceDate,
        deviceIsConnected: (payload.firmwareVersion) ? true : false,
        activeProgram: {
          pressure: payload.activeProgram.pressure,
          temperature: payload.activeProgram.temperature,
          progress: payload.activeProgram.progress,
          programId: payload.activeProgram.programId,
          power: payload.activeProgram.power,
          currentAction: payload.activeProgram.currentAction,
          estimatedTimeLeft: payload.activeProgram.estimatedTimeLeft,
          timeElapsed: payload.activeProgram.timeElapsed,
          warning: payload.activeProgram.warning,
        },
      };

    case Actions.LOADING:
      return { ...state, isLoading: payload.isLoading };

    case Actions.LOGIN:
      return { ...state, channelId: payload.channelId };

    case Actions.DISCONNECTED: {
      return {
        ...state,
        deviceIsConnected: false,
      };
    }

    case Actions.LOGOUT:
      return initialState;

    case Actions.RESETMACHINE: {
      iotPublish(state.channelId, {
        action: MachineActions.RESETMACHINE,
      });

      return {
        ...state,
        machineState: MachineStateType.IDLE,
        activeProgram: {
          ...state.activeProgram,
          programId: ProgramId.NONE,
          currentAction: "",
          progress: 1,
        },
      };
    }

    case Actions.CLEAN_VALVE_1: {
      iotPublish(state.channelId, {
        action: Actions.CLEAN_VALVE_1,
      });
      return {
        ...state,
      };
    }

    case Actions.CLEAN_VALVE_2: {
      iotPublish(state.channelId, {
        action: Actions.CLEAN_VALVE_2,
      });
      return {
        ...state,
      };
    }

    case Actions.CLEAN_VALVE_3: {
      iotPublish(state.channelId, {
        action: Actions.CLEAN_VALVE_3,
      });

      return {
        ...state,
      };
    }

    case Actions.CLEAN_VALVE_4: {
      iotPublish(state.channelId, {
        action: Actions.CLEAN_VALVE_4,
      });

      return {
        ...state,
      };
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function AppContextProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const value = { state, dispatch };
  return (
    <CountStateContext.Provider value={value}>
      {children}
    </CountStateContext.Provider>
  );
}

function AppContext() {
  const context = useContext(CountStateContext);
  if (context === undefined) {
    throw new Error("AppContext must be used within a AppContextProvider");
  }
  return context;
}

export { AppContextProvider, AppContext };
