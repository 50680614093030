import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { MachineStateType, ProgramId } from "../../utils/constants";
import { iotMachinePublish, default as useIOT } from "../../utils/useIOT";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#444",
    width: "100%",
    height: "100vh",
    padding: theme.spacing(1),
  },

  button: {
    color: "#fff",
  },

  log: {
    width: "100%",
    border: "1px solid #eee",
    background: "#f8f8f8",
    height: 300,
    overflow: "auto",
  },

  col: {
    width: "50%",
    float: "left",
  },
}));

const DebugMachinePanel = () => {
  const { state } = AppContext();
  const classes = useStyles();
  const { iotState } = useIOT({ channelId: state.channelId });

  const getBaseParams = () => ({
    machineVersion: "v1.0",
    machineState: MachineStateType.RUNNING,
    activeProgram: {
      temperature: 30 + Math.random() * 20,
      pressure: 3 + Math.random() * 3,
      progress: Math.random(),
      programId: ProgramId.PROGRAM_1,
      power: Math.random(),
    },
  });

  const handleStart = () => {
    iotMachinePublish(state.channelId, {
      ...getBaseParams(),
      machineState: MachineStateType.RUNNING,
    });
  };

  const handlePause = () => {
    iotMachinePublish(state.channelId, {
      ...getBaseParams(),
      machineState: MachineStateType.PAUSED,
    });
  };

  const handleStop = () => {
    iotMachinePublish(state.channelId, {
      ...getBaseParams(),
      machineState: MachineStateType.IDLE,
      activeProgram: {
        ...getBaseParams().activeProgram,
        programId: ProgramId.NONE,
        progress: 1,
      },
    });
  };

  const handleStatus = () => {
    iotMachinePublish(state.channelId, {
      ...getBaseParams(),
    });
  };

  return (
    <Box component="div" className={classes.root}>
      <Button className={classes.button} onClick={handleStart}>
        Start
      </Button>
      <Button className={classes.button} onClick={handlePause}>
        Pause
      </Button>
      <Button className={classes.button} onClick={handleStop}>
        Stop
      </Button>
      <Button className={classes.button} onClick={handleStatus}>
        Status
      </Button>

      <div className={classes.col}>
        <h2>Incoming</h2>
        <pre className={classes.log}>
          {JSON.stringify(
            iotState?.filter((actions) => actions.receiver === "machine") ||
              "{}",
            null,
            2
          )}
        </pre>
      </div>

      <div className={classes.col}>
        <h2>Outgoing</h2>
        <pre className={classes.log}>
          {JSON.stringify(
            iotState?.filter((actions) => actions.receiver !== "machine") ||
              "{}",
            null,
            2
          )}
        </pre>
      </div>
    </Box>
  );
};

export default DebugMachinePanel;
