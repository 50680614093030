import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppContext } from "../../AppContext";
import { ActiveProgram, ProgramId, TOPBAR_HEIGHT } from "../../utils/constants";

export interface StyleProps {
  activeProgram: ActiveProgram;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    padding: theme.spacing(2),
    position: "fixed",
    top: TOPBAR_HEIGHT,
    height: ({ activeProgram }) =>
      `calc(100% - ${55 + TOPBAR_HEIGHT +
      (!activeProgram.programId || activeProgram.programId !== ProgramId.NONE || activeProgram.progress  === 1 ? 55 : 0)}px)`,
    width: "100%",
    overflow: "auto",
  },
}));

const ContentWrapper = ({ children }: { children: any }) => {
  const { state } = AppContext();
  const {
    activeProgram,
  } = state;
  const classes = useStyles({ activeProgram });

  return (
    <Box component="div" className={classes.root}>
      {children}
    </Box>
  );
};

export default ContentWrapper;
