import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';

import { LoginForm } from "../Components";

import bgImage from "./../assets/unsplash-weed.jpg";
import drizzleLogo from "./../assets/drizzle-logo.png";

const LoginView = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      loginRoot: {
        width: "100%",
        height: "100vh",
        backgroundImage: `linear-gradient(to bottom, rgb(0 150 136 / 80%), rgb(67 160 71 / 85%)), url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      logo: {
        width: 150,
        marginBottom: theme.spacing(2),
      },
      helpText: {
        marginTop: theme.spacing(2),
        color: "rgba(255,255,255,0.7)",
        "& > a": {
          color: "#fff",
        },
      },
      alertText: {
        marginBottom: "20px",
        marginTop: "20px",
        backgroundColor: "beige",
      },
      infoBlock: {
        width: "375px"
      }
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.loginRoot}>
      <img src={drizzleLogo} alt="Drizzle" className={classes.logo} />
      <div className={classes.infoBlock}>

        <Alert className={classes.alertText} severity="info">Locate your Merlin400 SSID (Sticker on back)<p></p>SSID format <i>merlin_ap_123456</i>: Cookie based Merlin400 device, do <u>not</u> use this page to login. Follow this guide instead: <a href="https://drizzle.life/knowledge-base-2/basic_use/initial-app-setup-and-firmware-update/" target="_blank" rel="noopener noreferrer">Cookie connection</a><p></p>SSID format <i>m123456</i>: Login based Merlin400 device. Please proceed with login on this page. Before proceeding please make sure your Merlin400 is connected to the internet. Complete guide here: <a href="https://drizzle.life/knowledge-base-2/basic_use/how-to-use-the-app-complete-guide/" target="_blank" rel="noopener noreferrer">Login connection</a></Alert>
      </div>
      
      <LoginForm />
      <Typography
        variant="body2"
        color="textSecondary"
        component="p"
        className={classes.helpText}
      >
        Get help setting up the machine on {" "}
        <a
          href="https://www.drizzle.life/?utm_source=app&amp;utm_medium=login&amp;utm_campaign=help-cta"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.drizzle.life
        </a>
      </Typography>
    </div>
  );
};

export default LoginView;
