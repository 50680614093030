import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";

import {
  NavigationId,
  ProgramId,
  ProgramsType,
} from "../../utils/constants";

import { AppContext } from "../../AppContext";
import theme from "../../theme";

const ProgramBox = ({ program }: { program: ProgramsType }) => {
  const useStyles = makeStyles({
    root: {
      width: "100%",
      borderTop: `2px solid ${program.color || ""}`,
      backgroundImage: `linear-gradient(to bottom, rgba(red,0.25), transparent 30%)`,
    },
    icon: {
      color: program.color || "",
      marginRight: theme.spacing(1),
    },
  });

  const history = useHistory();
  const classes = useStyles();
  const { state } = AppContext();
  const {
    activeProgram: { programId },
  } = state;
  const isCurrentProgram = programId === program.id;
  const hasActiveProgram = programId !== ProgramId.NONE;
  const soakTime = localStorage.getItem("machine-soak-time") || 30;
  const [textVal, setTextVal] = useState(soakTime);

  const gotoDashboard = () => {
    history.push(`/${NavigationId.DASHBOARD}`);
  };

  const startProgram = () => {
    localStorage.setItem("drizzle-programId", program.id);
    localStorage.setItem("drizzle-soak-time", textVal.toString());
    history.push(`/${NavigationId.DASHBOARD}`);
  };

  return (
    <>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              <Icon fontSize="small" className={classes.icon}>
                {program.icon}
              </Icon>
              {program.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {program.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {isCurrentProgram && (
            <Button variant="contained" color="primary" onClick={gotoDashboard}>
              Status
            </Button>
          )}

          {!isCurrentProgram && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={startProgram}
                disabled={hasActiveProgram}
              >
                Select Program
              </Button>

              {program.hasOwnProperty("soakTimeDefault") && (
                <TextField
                id="soak-time-input"
                label="Enter soak time (seconds)"
                className="soakTime"
                style = {{ flex: 1 }}
                value={textVal}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || (re.test(e.target.value) && parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 1800 )){
                    setTextVal(e.target.value)
                  }
                }}
                required
                />
              )}
            </>
          )}
        </CardActions>
      </Card>
    </>
  );
};

export default ProgramBox;
