const shared = {
  IDENTITY_POOL_ID: "eu-central-1:f66c2c34-8a42-4f46-9e61-8dbe26624ea0",
  REGION: "eu-central-1",
  USER_POOL_ID: "eu-central-1_IW2Yb9GOg",
  USER_POOL_WEB_CLIENT_ID: "7e4tm9t0egnhoroc9ibs3hmf9t",
  MQTT_ID: "a1xm8wft3aqcak-ats",
};

const dev = {
  ...shared,
  API_URL: "https://api-dev.drizzle.life",
  FW_BUCKET: "drizzle-fw-releases-dev",
};

const prod = { ...shared, API_URL: "https://api.drizzle.life", FW_BUCKET: "drizzle-fw-releases", };

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;

export default config;
