export const mapDataFromAPItoStore = (data) => ({
  ...data,
  activeProgram: {
    pressure: data.pressure,
    temperature: data.temperature,
    progress: data.progress,
    power: data.power,
    programId: data.programId,
  },
  machineState: data.machineState,
  machineVersion: data.machineVersion,
  firmwareVersion: data.firmwareVersion,
  machineSoakTimeSeconds: data.programParameters?.soakTime || null,
  ...(data.log && { log: data.log }),
});
