import { makeStyles } from "@material-ui/core/styles";
import { ProgramBox } from "../Components";
import theme from "../theme";
import { Programs } from "../data";

const useStyles = makeStyles({
  programBoxes: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(345px, 1fr))",
    alignItems: "start",
    justifyItems: "center",
    gap: theme.spacing(2),
  },
});

const ProgramsView = (props: any) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.programBoxes}>
        {Object.keys(Programs).map((key) => (
          <ProgramBox program={Programs[key]} key={key} />
        ))}
      </div>
    </div>
  );
};
export default ProgramsView;
